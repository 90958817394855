







import { Component, Vue } from 'vue-property-decorator'
import VNavButton from '@/components/v-nav-button.vue' 

@Component({
  components: { VNavButton }
})
export default class extends Vue {

}
