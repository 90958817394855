


















import { Component, Vue } from 'vue-property-decorator';
import vFreeApply from '@/components/v-free-apply.vue';
import VBanner from '@/components/v-banner.vue';
import VCard from '@/components/v-card.vue'
import ShopGo from './components/go.vue'
import staffData from '../../assets/data/staff'

@Component({
  components: { vFreeApply,VBanner, VCard, ShopGo }
})
export default class extends Vue {
  private bannerData = staffData.banner
  private cardsData = staffData.cards
}
