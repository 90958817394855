export default{
  banner: {
    titleImg: require('../images/staff/banner_title.png'),
    video:{
      src: 'https://video.xmheigu.com/customerTrans/f9ff2b17705f05de53109ec752622612/2e828f64-17920cb29da-0005-502e-0e5-a9c95.mp4',
      poster: require('../images/public/poster/staff.jpg'),
      title: '',
      dec: '如何让员工像老板一样积极干活？如何通过机制实现员工优胜劣汰？员工合伙机制一招可以解决。一个好的管理机制不仅可以点燃员工积极性，还可以帮助门店提升服务品质，锁住稳定客源，实现持续盈利增收。'
    }
  },
  cards:[
    {
      title: '如何让员工比老板还要积极的干活？',
      content: '老板的想法跟员工的想法往往是背道而驰的，老板想让员工积极主动的干活，而员工则是拿多少钱干多少活，那么如何让员工比老板还要积极的干活呢？通过员工合伙机制就可以做到！员工合伙机制就是把员工变成不占股份的合作人，这样每个员工就会把客户当成自己的客户了。通过机制的变化，每个客户只要再次到店消费，员工都可以获得持续的收益，客户在门店消费的金额越高，员工的收益也越高，所有的这些收益通过黑谷的系统都可以做到秒到账，这样一来每个员工都很有感觉。每个行业都可以根据岗位设置不同的奖励，哪位员工工作积极，管理后台一眼就能看出来，在这样的机制下，所有岗位的员工都会比老板更积极工作。'
    },
    {
      title: '员工合伙机制解决门店的4大问题',
      list: [
        '保证服务品质，提高客户回店率',
        '让每个客户都主动评价，及时发现门店问题，杜绝客户流失',
        '保证服务质量，保持创新力',
        '有效的进行客户裂变，客源轻松增长2倍以上'
      ]
    },
    {
      title: '超级员工',
      content: '超级员工服务的客户是“自己的客户”，而不是“老板的客户”，因此服务的积极性、品质明显高于普通员工，流动性却远低于普通员工。'
    },
    {
      title: '超级员工系统优势',
      list: [
        ' 每个岗位的薪酬用系统来设计',
        '一对一设计企业利润与员工利润的平衡',
        '打破固定薪酬制，实行贡献价值薪酬',
        '薪酬与绩效完全融合',
        '一切用数据说话、明确各项标准与要求',
        '对于不同层级的员工，建议设计激励性薪酬解决方案',
        '弹性薪酬的设计',
        '满足员工对收入不断增长的预期',
        '员工收入增长与价值、能力、职位提升的匹配',
        '人人头上有目标，但不是公司为员工定任务',
        '做到老板100%想推送，管理层100%推送，执行者',
        '激发员工主动性，提升工作效率',
        '绩效=价值=价格，合理计划',
        '赚不到钱，是因为人没有用好',
        '人没用好，是因为钱没有分好',
        '先做好激励计划,再做工作计划'
      ]
    },
  ]
}